import { Popper } from "@mui/material";
import type { HTMLProps, ReactNode } from "react";
import { Children, isValidElement, useRef, useState } from "react";

import { useIsomorphicLayoutEffect, useScrollBottomSpy } from "@shared/hooks";

import { DropdownCategory } from "../DropdownCategory";
import { useDropdownContext } from "../DropdownContext";

import * as styles from "./DropdownContent.styles";

let DropdownCategoryType;

const getDropdownCategoryType = () => {
  if (!DropdownCategoryType) {
    DropdownCategoryType = (<DropdownCategory text="nada" categoryId="nuh uh" />).type;
  }

  return DropdownCategoryType;
};

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * If you have a fixed element at the "footer" of your dropdown content, you can offset
   * where the scroll "fade" gradient appears. Specified in pixels.
   *
   * @default 0
   */
  bottomScrollFadeOffset?: number;
  /**
   * The dropdown content
   *
   * @default undefined
   */
  children?: ReactNode | ReactNode[];
}

const DropdownContent = ({ bottomScrollFadeOffset, children, ...rest }: Props) => {
  const {
    activeCategoryId,
    isDropdownOpen,
    onTriggerClicked,
    setMouseOverContent,
    isFullWidth,
    isFullHeight,
    triggerElement,
    dropdownPlacement,
    customDropdownHeight,
    customDropdownWidth,
  } = useDropdownContext();

  const ref = useRef(null);
  const useLayoutEffect = useIsomorphicLayoutEffect();
  const [scrollTopToRestore, setScrollTopToRestore] = useState<number>();
  const { isAtBottom, handleScroll } = useScrollBottomSpy(ref);

  useLayoutEffect(() => {
    if (ref?.current) {
      if (activeCategoryId) {
        setScrollTopToRestore(ref.current.scrollTop);
        ref.current.scrollTop = 0;
      } else if (scrollTopToRestore >= 0) {
        ref.current.scrollTop = scrollTopToRestore;
      }
    }
  }, [activeCategoryId]);

  if (!isDropdownOpen || !triggerElement) return null;

  if (isDropdownOpen && !triggerElement) {
    onTriggerClicked();

    return null;
  }

  const onMouseEnterHandler = () => {
    setMouseOverContent(true);
  };

  const onMouseLeaveHandler = () => {
    setMouseOverContent(false);
  };

  const conditionalStyle = () => {
    return [
      styles.dropdownContent,
      isFullWidth && styles.isFullWidth,
      isFullHeight && styles.isFullHeight,
      customDropdownHeight && styles.customHeight(customDropdownHeight),
      customDropdownWidth && styles.customWidth(customDropdownWidth),
      isAtBottom && styles.scrolledToBottom,
      bottomScrollFadeOffset && styles.bottomScrollOffset(bottomScrollFadeOffset),
    ];
  };

  const popperStyles = [styles.popper, isFullWidth && styles.isFullWidth];

  const renderContent = () => {
    if (activeCategoryId) {
      const childs = Children.toArray(children);

      return childs.find(child => {
        if (isValidElement(child) && child.type === getDropdownCategoryType()) {
          return child.props?.categoryId === activeCategoryId;
        }

        return false;
      });
    }

    return children;
  };

  return (
    <Popper
      placement={dropdownPlacement}
      open={isDropdownOpen}
      disablePortal
      anchorEl={triggerElement}
      css={popperStyles}
    >
      <div
        css={conditionalStyle()}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onScroll={handleScroll}
        data-testid="dropdown-content"
        ref={ref}
        role="menu"
        {...rest}
      >
        {renderContent()}
      </div>
    </Popper>
  );
};

export { DropdownContent };
